import { getAppId, getGen } from '../twa'
import { getAppConfig, getAppConfigParam } from '../appConfigUtil'
import { getConfig, getParentDomain } from '../urlUtils'
import { getState } from '../StoreManager'

const IOS_FM_APP_ID = 'com.flirtymania.videochat'
const IOS_UV_APP_ID = 'ulive.tv'
export const AND_UV_APP_ID = 'com.tv.ulive'
export const MYCHARMAI_APP_ID = 'mycharmai.com'

export const AND_UD_APP_ID = 'ulive.dating'

export const IOS_DATING_APP_ID = 'flirtymania.dating'

export const MS_PWA = '27386FamilyTrain.FlirtymaniaDatingChat'
export const MS_PWA_AI = '27386FamilyTrain.15373060F9666'

export const SPECIAL_UID = 'b44fd2d1cd83b8e332c49c8d6e05d3db'
const skinCallGen = 24

export function isSkinForCall() {
  // return true
  if (isIOSPWA() && getGen() > skinCallGen) {
    return true
  }
  return false
}

export function isSpecialUid(uid) {
  return uid === SPECIAL_UID
}

export function isIOSPWA() {
  // const pf = getState('platform')
  // const iosPF = pf === 'iOS'
  // console.log('!!!!iosPF ' + iosPF)
  // return  iosPF
  // return getState('platform') === 'iOS'
  // return true
  const appId = getAppId()
  return appId === IOS_FM_APP_ID ||
      appId === IOS_UV_APP_ID ||
      appId === IOS_DATING_APP_ID ||
      appId === MYCHARMAI_APP_ID
}

export function isRestrictedMode() {
  return false
  // return true
  const appID = getAppId()
  return appID === IOS_UV_APP_ID// || appID === AND_UV_APP_ID
}

export function isCustomVanilla() {
  // return true
  const config = getAppConfig()
  return config.simple
  // return true
  // const appID = getAppId()
  // return appID === IOS_UV_APP_ID
}

export function iosPWABuyClick(params) {
  try {
    window.webkit.messageHandlers.PwaSend.postMessage(
      JSON.stringify({ pay: params })
    )

    // window.webkit.messageHandlers.test.postMessage(JSON.stringify(params))
  } catch (err) {
    alert(err)
    // window.webkit.messageHandlers.test.postMessage(JSON.stringify(params))
  }
}

export function openLinkHack(url) {
  if (isIOSPWA()) {
    let safariHack = document.getElementById('safariHack')
    if (!safariHack) {
      window.open(url, 'noopener noreferrer')
      return
    }
    safariHack.setAttribute('href', url)
    safariHack.click()
    return
  }

  window.open(url, 'noopener noreferrer')
}

export function isUV() {
  const apId = getAppId()
  return apId === IOS_UV_APP_ID || apId === AND_UV_APP_ID || apId === AND_UD_APP_ID || getParentDomain().includes('ulive.chat')
}

export function isCBTWA() {
  // return false
  const appId = getAppId()
  return !isIOSPWA()//appId === AND_UV_APP_ID || appId === AND_UD_APP_ID ||
}

